/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useContext } from 'react';
import { Box, Grid } from '@mui/material';
import styles from './viewSustainability.module.scss';
import { ObraProperties } from '../../../../../types/ObraDetails';
import { useTranslation } from 'react-i18next';
import TitleHelp from '../../../components/titleHelp/titleHelp';
import SimpleSelect from '../../../components/simpleSelect/simpleSelect';
import { UserModeContext } from '../../../../../context/UserModeProvider';
import NoDataPicture from '../../../components/noDataPicture/noDataPicture';
import sustainabilityNoData from '../../../../../assets/sustainabilityNoData.svg';
import { AuthContext } from '../../../../../services/authContextProvider';

interface ViewSustainabilityProps {
  obra: ObraProperties
}

const ViewSustainability: FC<ViewSustainabilityProps> = (props) => {
  const { obra } = props;
  const [t] = useTranslation();
  const userModeContext = useContext(UserModeContext);
  const authContext = useContext(AuthContext);

  if (obra.esg.certificadosSostenibilidad) {
    obra.esg.certificadosSostenibilidad.sort((a: any, b: any) => b.codCertificado.localeCompare(a.codCertificado));
  }

  return (
    <div data-testid='edit-sustainability-test' className={styles.containerSustainability}>

      <div data-testid="sustainability-edit-test">
        <TitleHelp
          id='sustainability'
          value={t('details.esg.sustainability.helper')}
          help={t('help.esg.sustainabilityCertificates')}
          addItemText={t('details.esg.sustainability.add') as string}
        />
        <Box sx={{ mt: '14px'}}>
          {
            obra.esg.certificadosSostenibilidad?.map((cert, index) => {
              return (<div id="certificadosSostenibilidad-list" key={`certificadosSostenibilidad-${index}`} data-testId={`certificadosSostenibilidad-${index}`}>
                <Grid container spacing={2} columnSpacing={3}>
                  <Grid item xs={cert.origen === 'cecoc' ? 4 : 8}>
                    <SimpleSelect
                      id={`document-type-${index}`}
                      options={[]}
                      value={{ id: -1, nombre: cert.nomCertificado }}
                      label={t('details.esg.sustainability.type')}
                      editable={false}
                      customClassName={styles.itemContainer}
                    />
                  </Grid>
                  {cert.origen === 'cecoc' && <Grid item xs={4}>
                    <SimpleSelect
                      id={`document-type-levelInit-${index}`}
                      options={[]}
                      value={{ id: -1, nombre: cert.nomNivel }}
                      label={t('details.esg.sustainability.levelInit')}
                      editable={false}
                      customClassName={styles.itemContainer}
                    />
                  </Grid>}
                  <Grid item xs={4}>
                    <SimpleSelect
                      id={`document-type-levelUpdate-${index}`}
                      options={[]}
                      value={{ id: -1, nombre: cert.nomNivelActualizado || '-' }}
                      label={t('details.esg.sustainability.levelUpdate')}
                      editable={false}
                      customClassName={styles.itemContainer}
                    />
                  </Grid>
                </Grid>
              </div>
              );
            })
          }
        </Box>
        {
          !obra.esg.certificadosSostenibilidad || obra.esg.certificadosSostenibilidad.length === 0 ?
            <div>
              < NoDataPicture
                id='designer-nodata'
                picture={sustainabilityNoData}
                text={authContext.hasEditorRole() && userModeContext.userMode !== 'editorDisabled'
                  ? t('details.esg.sustainability.changeUser')
                  : t('details.esg.sustainability.noData') + (authContext.hasEditorRole() ? '. ' + t('details.validation.noEditMode') : '')}
              />
            </div>
            : null
        }
      </div >
    </div>);
};

export default ViewSustainability;
