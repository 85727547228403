import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import es from './translations/es.json';
import en from './translations/en.json';
import pl from './translations/pl.json';
import pt from './translations/pt.json';
import { AuthContextProvider, initAuthContextProvider } from './services/authContextProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PermissionError from './pages/permissionError/permissionError';
import FullDetail, { tabMap } from './pages/fullDetail/fullDetail';
import AdministrationPanel from './pages/administrationPanel/administrationPanel';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/bitter/400.css';
import '@fontsource/bitter/700.css';
import '@fontsource/bitter/400-italic.css'; // Specify weight and style
import AdvancedSearch from './pages/advancedSearch/advancedSearch';
import PendingActions from './pages/pendingActions/pendingActions';
import DetailsViewProvider from './context/DetailsViewProvider';
const defaultLanguage = localStorage.getItem('language') && localStorage.getItem('language') !== '' ? localStorage.getItem('language') : 'es';

void i18next.init({
  interpolation: { escapeValue: false },
  resources: {
    es: {
      default: es
    },
    en: {
      default: en
    },
    pl: {
      default: pl
    },
    pt: {
      default: pt
    }
  },
  defaultNS: 'default',
  lng: defaultLanguage as string,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

initAuthContextProvider().then(() => {
  root.render(
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <Routes>
          <Route path="/error-permisos" element={
            <AuthContextProvider>
              <PermissionError error={'permissions'} />
            </AuthContextProvider>} />
          <Route path="/error-usuario" element={
            <AuthContextProvider>
              <PermissionError error={'user'} />
            </AuthContextProvider>} />
          <Route path="/" element={
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          }>
            <Route path="/admin" element={
              <AuthContextProvider>
                <AdministrationPanel />
              </AuthContextProvider>} />
            <Route path="/notificaciones" element={
              <AuthContextProvider>
                <PendingActions />
              </AuthContextProvider>} />
            <Route path="/busqueda-avanzada" element={
              <AuthContextProvider>
                <AdvancedSearch />
              </AuthContextProvider>} />
            <Route path="/:codCentro/detalles" element={
              <AuthContextProvider>
                <DetailsViewProvider>
                  <FullDetail tab={0}/>
                </DetailsViewProvider>
              </AuthContextProvider>} />
            {
              Object.keys(tabMap).map((tab) => (  
                <Route key={tab} path={`/:codCentro/detalles/${tab}`} element={
                  <AuthContextProvider>
                    <DetailsViewProvider>
                      <FullDetail tab={tabMap[tab as keyof typeof tabMap]} />
                    </DetailsViewProvider>
                  </AuthContextProvider>
                } />
              ))
            }
          </Route>
        </Routes>
      </BrowserRouter>
    </I18nextProvider >
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
